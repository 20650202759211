import React, { useState } from "react";
import service_data from "../../data/services";
import ServiceCard from "../card";

const Filter = () => {
  const [description, setDescription] = useState("");

  const [foundServices, setFoundServices] = useState(service_data());

  const filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const result = service_data().filter((service) => {
        return (
          service.description.toLowerCase().indexOf(keyword.toLowerCase()) !==
          -1
        );
      });
      setFoundServices(result);
    } else {
      setFoundServices(service_data());
    }

    setDescription(keyword);
  };

  return (
    <div className="search-container">
      <input
        type="search"
        value={description}
        onChange={filter}
        className="input"
        placeholder="Rechercher"
      />

      <div className="main-container">
        {foundServices && foundServices.length > 0 ? (
          foundServices.map((item) => (
            <ServiceCard
              key={item.id}
              description={item.description}
              image={item.image}
              url={item.url}
            />
          ))
        ) : (
          <h1>No result found !</h1>
        )}
      </div>
    </div>
  );
};

export default Filter;
